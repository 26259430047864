<template>
  <div class="estimateContentRegistration">
    <h1 class="h2">見積内容登録</h1>

    <v-form ref="form" class="px-5 mt-5">
      <v-row>
        <!-- <v-col>
          <v-autocomplete v-model="selecthospital" :items="hospitals" label="病院" outlined item-text="name"
            item-value="code" return-object></v-autocomplete>
        </v-col> -->
        <v-col cols="6">
          <v-text-field
            v-model.number="quoteNumber"
            label="見積番号"
            type="number"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>

      <v-radio-group
        v-model="selectType"
        row
        label=""
        prepend-icon="mdi-toggle-swtich"
      >
        <v-radio label="定期" :value="Types.Regular"></v-radio>
        <v-radio label="定期" :value="Types.Spot"></v-radio>
      </v-radio-group>

      <v-row>
        <v-col cols="6">
          <v-text-field label="病院" outlined clearable></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="billAmount"
            type="number"
            label="請求金額"
            outlined
            suffix="円"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            v-model="workContent"
            label="作業内容"
            outlined
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <div class="d-flex flex-row-reverse mt-5">
        <v-btn type="login" color="success">
          完了
        </v-btn>
      </div>
      <!-- *************** form END *************** -->
    </v-form>
  </div>
</template>

<script>
const Types = {
  Regular: 0,
  Spot: 1
};

export default {
  components: {},
  data() {
    return {
      selectType: Types.Spot,
      Types,
      quoteNumber: "49073",
      billAmount: "126000",
      workContent: "消防訓練立会い"
    };
  }
};
</script>

<style>
.estimateContentRegistration .v-input__prepend-outer {
  display: none;
}

.estimateContentRegistration .v-text-field__details {
  display: none;
}
</style>
